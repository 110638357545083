import { createRouter, createWebHistory } from 'vue-router'
//import packageJson from '../../package.json'

const routes = [
    {
        // path: url 경로, component: 표시될 컴포넌트
        path: '/yoyangsys',
        name: 'Yoyangsys',
        beforeEnter(from) {
            window.open('https://ltrc.yoyangsys.co.kr', '_blank')
            console.log(from)
        },
    },
    {
        path: '/yys-playstore',
        name: 'YysApp',
        beforeEnter(from) {
            window.open('https://play.google.com/store/apps/details?id=com.yoyang.yysemp', '_blank')
            console.log(from)
        },
    },
    {
        path: '/',
        name: 'YysHome',
        component: () => import('../views/pages/YysHome.vue'),
        children: [
            {
                path: '/',
                name: 'MainComp',
                component: () => import('../views/MainComp.vue')
            },
            {
                path: '/pricing',
                name: 'PriceComp',
                meta: {
                    title: '이용요금 | 요양SYS',
                },
                component: () => import('../views/PriceComp.vue')
            },
            {
                path: '/contact-sales',
                name: 'ContactComp',
                meta: {
                    title: '도입문의 | 요양SYS',
                },
                component: () => import('../views/ContactComp.vue')
            },
            {
                path: '/externalLink',
                name: 'ExtSites',
                meta: {
                    title: '정보 | 요양SYS',
                },
                component: () => import('../views/ExtSites.vue')
            },
            {
                path: '/help',
                name: 'HelpDeskComp',
                meta: {
                    title: '고객센터 | 요양SYS',
                },
                component: () => import('../views/HelpDeskComp.vue')
            },
            {
                path: '/notice',
                name: 'NoticeComp',
                meta: {
                    title: '공지사항 | 요양SYS',
                },
                component: () => import('../views/NoticeComp.vue')
            },
            {
                path: '/noticeDtl/:bbsPk',
                name: 'NoticeDtlComp',
                meta: {
                    title: '공지사항 | 요양SYS',
                },
                props: true,
                component: () => import('../views/NoticeDtlComp.vue')
            },
            {
                path: '/faq',
                name: 'FaqComp',
                meta: {
                    title: '자주묻는질문 | 요양SYS',
                },
                component: () => import('../views/FaqComp.vue')
            },
            {
                path: '/svc-bnfcr',
                name: 'BnfcrSvc',
                meta: {
                    title: '수급자정보관리 소개 | 요양SYS',
                },
                component: () => import('../components/service/BnfcrSvc.vue')
            },
            {
                path: '/svc-bnftrcrd',
                name: 'BnftrcrdSvc',
                meta: {
                    title: '급여제공기록관리 소개 | 요양SYS',
                },
                component: () => import('../components/service/BnftrcrdSvc.vue')
            },
            {
                path: '/svc-sfty',
                name: 'SftyInspSvc',
                meta: {
                    title: '환경및안전관리 소개 | 요양SYS',
                },
                component: () => import('../components/service/SftyInspSvc.vue')
            },
            {
                path: '/svc-fcsft',
                name: 'FcsftOprSvc',
                meta: {
                    title: '기관운영 관리 소개 | 요양SYS',
                },
                component: () => import('../components/service/FcsftOprSvc.vue')
            },
            {
                path: '/svc-empinf',
                name: 'EmpInfSvc',
                meta: {
                    title: '직원 정보관리 소개 | 요양SYS',
                },
                component: () => import('../components/service/EmpInfSvc.vue')
            },
            {
                path: '/svc-bnfcrcopay',
                name: 'BnfcrCopayRateSvc',
                meta: {
                    title: '본인부담금 소개 | 요양SYS',
                },
                component: () => import('../components/service/BnfcrCopayRateSvc.vue')
            },
            {
                path: '/svc-impexp',
                name: 'ImpExpSvc',
                meta: {
                    title: '수입 지출 관리 소개 | 요양SYS',
                },
                component: () => import('../components/service/ImpExpSvc.vue')
            },
            {
                path: '/fcltysite',
                name: 'FcltySite',
                meta: {
                    title: '기관홈페이지 제작 | 요양SYS',
                },
                component: () => import('../views/FcltySite.vue')
            },
        ],
    },
    {
        path: '/mnl',
        name: 'YysMnl',
        meta: {
            title: '가이드 | 요양SYS',
        },
        component: () => import('../views/pages/YysMnl.vue'),
        children: [
            {
                path: '/mnl/:mnlPk',
                name: 'CmmMnlDtl',
                meta: {
                    title: '가이드 | 요양SYS',
                },
                props: true,
                component: () => import('../components/commons/CmmMnlDtl.vue')
            },
        ],
    },
    {
        path: '/prmt-smr',
        name: 'YysPrmtSmr',
        meta: {
            title: '여름 프로모션 | 요양SYS',
        },
        component: () => import('../views/pages/YysPrmtSmr.vue'),        
    },
    {
        path: '/join',
        name: 'YysJoin',
        meta: {
            title: '회원가입 | 요양SYS',
        },
        component: () => import('../views/pages/YysJoin.vue'),        
    },
    {
        path: '/prmt-smr-banner',
        name: 'YysPrmtSmrBanner',
        meta: {
            title: '여름 프로모션 | 요양SYS',
        },
        component: () => import('../views/pages/YysPrmtSmrBanner.vue'),        
    },
    {
        path: '/prmt-smr-dm',
        name: 'YysPrmtSmrDm',
        meta: {
            title: '여름 프로모션 | 요양SYS',
        },
        component: () => import('../views/pages/YysPrmtSmrDm.vue'),        
    },
    {
        path: '/est-print',
        name: 'EstPrint',
        meta: {
            title: '견적서 출력 | 요양SYS',
        },
        component: () => import('../views/EstPrint.vue'),        
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior (to) {
        if (to.hash) {
            return { el: to.hash }
        }
        else
            return { top: 0 }
    },
})

// router.afterEach((to) => {
//     let title = to.meta.title || packageJson.name
//     if(title) document.title = title
// })

export default router